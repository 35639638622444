import React from "react"
import "./style.scss"
import texture from "../../../assets/images/heading__texture.png"
import CTABtn from "../../../components/buttons/CTABtn"
import aboutBulb from "../../../assets/images/aboutbulb.png"

const Index = ({ title }) => {
  return (
    <div className="about__hero__main container">
      <div className="image__section">
        <div className="image__box">
          <div className="image__sub__bos"></div>
          <div className="bulb__section">
            <img
              src={aboutBulb}
              alt="bulb"
            />
          </div>
        </div>
      </div>
      <div className="text__section">
        {title ? (
          <h2 className="d-flex">
            <img
              src={texture}
              alt="texture"
              className="right__texture"
            />
            <img
              src={texture}
              alt="texture2"
              className="left__texture"
            />
            {title.split("").map((text, index) => (
              <div
                data-aos="flip-right"
                key={index}>
                {text}
              </div>
            ))}
          </h2>
        ) : (
          <h2 className="d-flex">
            <img
              src={texture}
              alt="texture"
              className="right__texture"
            />
            <img
              src={texture}
              alt="texture2"
              className="left__texture"
            />
            <div data-aos="flip-right">A</div>
            <div data-aos="flip-right">B</div>
            <div data-aos="flip-right">O</div>
            <div data-aos="flip-right">U</div>
            <div data-aos="flip-right">T</div>
          </h2>
        )}
        {title == "PRICING" ? (
          <p
            data-aos="fade-up"
            className="about__description">
            Find Your Perfect Plan
          </p>
        ) : (
          <p
            data-aos="fade-up"
            className="about__description">
            The web experts
          </p>
        )}

        <div
          data-aos="fade-up"
          className="btn__section">
          <CTABtn text={"SUBMIT A BRIEF"} />
        </div>
      </div>
    </div>
  )
}

export default Index
