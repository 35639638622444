import React, { useContext } from "react";
import "./style.scss";
import topSketch from "../../assets/images/footer-bottom-texturev2.png";
import { AuthContext } from "../../context";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillGooglePlusSquare,
} from "react-icons/ai";
import { FaSquareInstagram } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";

const Index = () => {
  const { setCursor } = useContext(AuthContext);

  const mouseHover = () => setCursor("fill");
  const mouseOut = () => setCursor("");

  const social = [
    {
      Icon: BsFacebook,
      link: "https://www.facebook.com/profile.php?id=61559611012131",
    },
    {
      Icon: AiOutlineTwitter,
      link: "https://twitter.com",
    },
    {
      Icon: FaSquareInstagram,
      link: "https://www.instagram.com",
    },
    {
      Icon: BsLinkedin,
      link: "https://www.linkedin.com",
    },
    {
      Icon: AiFillYoutube,
      link: "https://www.facebook.com",
    },
    {
      Icon: AiFillGooglePlusSquare,
      link: "https://www.facebook.com",
    },
  ];

  const services = [
    {
      name: "Web Design",
      link: "webdesign",
    },
    {
      name: "Web Development",
      link: "webdevelopment",
    },
    {
      name: "Brand Marketing",
      link: "designbranding ",
    },
    {
      name: "Digital Marketing",
      link: "digitalmarketing",
    },
  ];

  return (
    <div className="footer__main">
      <div className="footer__sub__section container">
        <div className="row m-0 p-0">
          <div className="col-md-4 m-0 p-0 logo__section">
            <img src="/logo512.png" alt="logo" style={{ maxWidth: 200 }} />
          </div>
          <div className="row col-md-8 m-0 p-0">
            <div className="col-md-6 list__section">
              <h3>Services</h3>
              {services.map((v, i) => {
                return (
                  <a key={i} href={v?.link} className="list__item">
                    <div onMouseOver={mouseHover} onMouseOut={mouseOut}>
                      {v?.name}
                    </div>
                  </a>
                );
              })}
            </div>
            <div className="col-md-6 social__section">
              <h3>Contact</h3>
              <div>
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="d-flex mt-2 gap-2 align-items-center"
                >
                  <div></div>
                  <div>
                    <a href="tel:+44 730 1494637" className="list__item">
                      <BsFillTelephoneFill />
                      &nbsp;&nbsp; +44 730 1494637
                    </a>
                  </div>
                </div>
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="d-flex mt-2 gap-2 align-items-center"
                >
                  <div></div>
                  <div>
                    <a href="tel:+1 (289) 295-1684" className="list__item">
                      <BsFillTelephoneFill />
                      &nbsp;&nbsp; +1 (289) 295-1684
                    </a>
                  </div>
                </div>
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="d-flex mt-2 gap-2 align-items-center"
                >
                  <div></div>
                  <div>
                    <a className="list__item" href="mailto:hello@apexverse.co">
                      <HiOutlineMail />
                      &nbsp;&nbsp; hello@apexverse.co
                    </a>
                  </div>
                </div>
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="d-flex mt-2 gap-2 align-items-center"
                >
                  <div style={{ paddingLeft: 10 }}>
                    {window?.innerWidth > 525 ? (
                      <FaMapMarkerAlt color="#fff" size={20} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div> </div>
                  {window?.innerWidth < 526 ? (
                    <div>
                      <a href="/" className="list__item">
                        <FaMapMarkerAlt color="#fff" size={20} />
                        &nbsp;&nbsp;513 Cheam Sutton London Road
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a href="/" className="list__item">
                        513 Cheam Sutton London Road
                      </a>
                    </div>
                  )}
                </div>
                <div
                  onMouseOver={mouseHover}
                  onMouseOut={mouseOut}
                  className="d-flex mt-2 gap-2 align-items-center"
                >
                  <div style={{ paddingLeft: 10 }}>
                    {window?.innerWidth > 525 ? (
                      <FaMapMarkerAlt color="#fff" size={20} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div> </div>
                  {window?.innerWidth < 526 ? (
                    <div>
                      <a href="/" className="list__item">
                        <FaMapMarkerAlt color="#fff" size={20} />
                        4717 Washington Blvd Suite B Halethrope, MD 21227
                      </a>
                    </div>
                  ) : (
                    <div>
                      <a href="/" className="list__item">
                        4717 Washington Blvd Suite B Halethrope, MD 21227
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-2 icons__section">
                {social?.map(({ Icon, link }, i) => {
                  return (
                    <a href={link} target="_blank" rel="noreferrer" key={i}>
                      <Icon
                        className="social__icon"
                        color={"rgb(201, 49, 114)"}
                        size={24}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="texture__section">
        <img src={topSketch} alt="topSketch" />
      </div>
      <div className="copy__right__section">
        <h3>
          "We recognize the traditional stewards of the places where we live,
          study, and work"
        </h3>
        <p>© APEX VERSE - ALL RIGHTS RESERVED</p>
      </div>
    </div>
  );
};

export default Index;
